import React, { FormEvent, useEffect, useState } from 'react';
import { Routes, Route, NavLink, useNavigate, useLocation, Navigate } from 'react-router-dom';
import {
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material/';

import { ReactComponent as Wallet } from '../../assets/wallet.svg';
import { ReactComponent as Mercado } from '../../assets/Mercado.svg';
import { ReactComponent as Simuladores } from '../../assets/Simuladores.svg';
import { ReactComponent as EstatAvanc } from '../../assets/user-chart.svg';
import { ReactComponent as TableList } from '../../assets/table-list.svg';
import { ReactComponent as Setas } from '../../assets/right-left-solid.svg';
import { ReactComponent as SetasDinheiro } from '../../assets/money-bill-transfer-solid.svg';

import PersistentDrawerLeft from '../../components/PersistentDrawerLeft';
import ResumoCarteira from './ResumoCarteira';
import PerformanceHistorica from './PerformanceHistorica';
import PosicaoConsolidada from './PosicaoConsolidada';
import RetornoAtivo from './RetornoAtivo';
import CarteiraExplodida from './CarteiraExplodida';
import FluxoAtivos from './FluxoAtivos';
import FluxoCaixa from './FluxoCaixa';
import EstatisticasAvancadas from './EstatisticasAvancadas';
import GerarPDFTodos from './GerarPDFTodos';
import { useTheme } from 'styled-components';
import { ChevronRight } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../services/reduxHooks';
import SelecionarCarteira from './SelecionarCarteira';
import MudarSenha from '../../components/Login/MudarSenha';
import { changePassword } from '../../services/api/preferenceAPI';
import { CustomModal } from '../../components/CustomModal';
import BotaoLogout from '../../components/BotaoUser/BotaoLogout';

const drawerWidth = 250;

const MainComponent = () => {
  const flagTrocaSenhaStore = localStorage.getItem('flagTrocaSenha');
  const [
    carteiraSelecionada,
    flagTrocaSenha,
    loadingTrocarSenha,
    trocarSenhaStatus,
    unauthorized,
    isLoadingMinimized,
    carteiraList,
    loadedCarteiraList,
    carteira,
  ] = useAppSelector((state) => [
    state.preference.params.carteira,
    flagTrocaSenhaStore || state.preference.flagTrocaSenha,
    state.preference.loadingTrocarSenha,
    state.preference.trocarSenhaStatus,
    state.preference.unauthorized,
    state.loadMinimized.isLoadingMinimized,
    state.preference.carteiraList,
    state.preference.loadedCarteiraList,
    state.preference.params.carteira,
  ]);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search, pathname } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [openModalUnauthorized, setOpenModalUnauthorized] = useState(false);
  const [error, setError] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  interface PageObject {
    linkTo: string;
    urlParam?: string;
    component: React.ReactElement;
    title: string;
    icon: React.ReactElement;
  }

  const pages: PageObject[] = [
    {
      linkTo: 'resumo-da-carteira',
      component: <ResumoCarteira showCards={true} showPieChart={true} />,
      title: 'Resumo da Carteira',
      icon: <Wallet fill={theme.fontColorMenu} />,
    },
    {
      linkTo: 'performance-historica',
      component: <PerformanceHistorica />,
      title: 'Performance Histórica',
      icon: <Mercado fill={theme.fontColorMenu} />,
    },
    {
      linkTo: 'posicao-consolidada',
      component: <PosicaoConsolidada />,
      title: 'Posição Consolidada',
      icon: <TableList fill={theme.fontColorMenu} />,
    },
    {
      linkTo: 'retorno-por-ativo',
      component: <RetornoAtivo />,
      title: 'Retorno por Ativo',
      icon: <Simuladores fill={theme.fontColorMenu} />,
    },
    // {
    //   linkTo: 'carteira-explodida',
    //   component: <CarteiraExplodida />,
    //   title: 'Carteira Explodida',
    //   icon: <PieChart fill={theme.fontColorMenu} />,
    // },
    {
      linkTo: 'fluxo-de-ativos',
      urlParam: `carteira=${carteiraSelecionada}&periodo=mes_atual`,
      component: <FluxoAtivos />,
      title: 'Fluxo de Ativos',
      icon: <Setas fill={theme.fontColorMenu} />,
    },
    {
      linkTo: 'estatisticas-avancadas',
      component: <EstatisticasAvancadas />,
      title: 'Estatísticas Avançadas',
      icon: <EstatAvanc fill={theme.fontColorMenu} />,
    },
  ];

  useEffect(() => {
    if (carteiraSelecionada === null) {
      navigate(`/${search}`);
    }
  }, [carteiraSelecionada]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenTrocarSenha = () => {
    setOpenModal(true);
    setError(false);
    handleClose();
  };

  const handleOpenUnauthorized = () => {
    setOpenModalUnauthorized(true);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const password = formData.get('novaSenha') as string;
    const password2 = formData.get('confirmeNovaSenha') as string;
    if (password !== password2 || !password) setError(true);
    else {
      dispatch(changePassword(password));
    }
  };

  useEffect(() => {
    if (typeof flagTrocaSenha === 'string') {
      localStorage.setItem('flagTrocaSenha', flagTrocaSenha);
    }
    if (flagTrocaSenha === '1') {
      handleOpenTrocarSenha();
    }
  }, [flagTrocaSenha]);

  useEffect(() => {
    if (unauthorized) {
      handleOpenUnauthorized();
    }
  }, [unauthorized]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [pathname]);

  useEffect(() => {
    if (loadedCarteiraList && carteiraList && carteiraList[0] && carteira) {
      if (!carteiraList.some((item) => item.nome_portfolio === carteira)) {
        navigate('/');
        window.location.reload();
      }
    }

    if (carteira === null) {
      navigate('/');
    }
  }, [carteiraList, carteira]);

  const MenuRoutes = (
    <Routes>
      <Route>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route key="selecionar-carteira" path="/" element={<SelecionarCarteira />} />
        {pages.map((page) => (
          <Route key={page.title} path={page.linkTo} element={page.component} />
        ))}
      </Route>
      <Route path="/pdf" element={<GerarPDFTodos />} />
    </Routes>
  );

  const ListaMenu = ({ onClick }: { onClick: any }) =>
    carteiraSelecionada !== null ? (
      <List sx={{ borderRight: theme.borderRightMenu }}>
        {pages.map((page) => (
          <React.Fragment key={page.title}>
            <NavLink
              to={{ pathname: `/${page.linkTo}`, search: page.urlParam ?? search }}
              style={({ isActive }) => ({
                textDecoration: 'none',
                ...(isLoadingMinimized && { pointerEvents: 'none' }),
                background: isActive
                  ? `linear-gradient(to right, ${theme.tabGradientColor} -120%, transparent 45%`
                  : '',
              })}
              onClick={onClick}>
              <ListItem disablePadding style={{ background: 'inherit' }}>
                <ListItemButton disabled={isLoadingMinimized}>
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText
                    primary={page.title}
                    style={{
                      color: theme.fontColorMenu,
                      whiteSpace: 'nowrap',
                      marginLeft: -33,
                      fontWeight: 'bold',
                    }}
                  />
                  <ChevronRight style={{ color: theme.fontColorMenu }} />
                </ListItemButton>
              </ListItem>
            </NavLink>
            <Divider sx={{ bgcolor: theme.backgroundColorMenuDivider }} />
          </React.Fragment>
        ))}
      </List>
    ) : null;

  return (
    <div>
      <CustomModal
        open={openModal}
        onCloseHandle={() => setOpenModal(false)}
        preventClosing
        minWidth={300}
        maxWidth={500}>
        <MudarSenha
          loading={loadingTrocarSenha}
          error={error}
          handleSubmit={handleSubmit}
          status={trocarSenhaStatus}
        />
      </CustomModal>
      <CustomModal
        open={openModalUnauthorized}
        onCloseHandle={() => setOpenModalUnauthorized(false)}
        preventClosing
        minWidth={300}
        maxWidth={500}>
        <div>
          Ferramenta não liberada. Entre em contato com o seu gestor.
          <BotaoLogout />
        </div>
      </CustomModal>
      <PersistentDrawerLeft
        drawerwidth={drawerWidth}
        ListaMenu={ListaMenu}
        MenuRoutes={MenuRoutes}
      />
    </div>
  );
};

export default MainComponent;
