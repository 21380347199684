import styled from 'styled-components';
interface IDivProps {
  smallerFontSize?: boolean;
  mb?: string;
  center?: boolean;
}

export const ContainerTitle = styled.h1<IDivProps>`
  font-weight: bold;
  color: ${({ theme }) => theme.titleFontColor};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  font-size: ${(props) =>
    props.smallerFontSize ? props.theme.mdFontSize : props.theme.lgFontSize};
  @media ${(props) => props.theme.device.mobileXL} {
    font-size: 18px;
  }
  margin-bottom: ${(props) => props.mb ?? ''};
  margin-right: 10px;
`;

export const ContainerSubTitle = styled.h2<IDivProps>`
  font-weight: bold;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  color: ${({ theme }) => theme.titleFontColor};
  font-size: ${(props) =>
    props.smallerFontSize ? props.theme.mdFontSize : props.theme.lgFontSize};
  margin-bottom: ${(props) => props.mb ?? ''};
`;

export const ContainerSmallerTitle = styled.h3<IDivProps>`
  font-weight: bold;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  color: ${({ theme }) => theme.titleFontColor};
  font-size: ${(props) => props.theme.smFontSize};
  margin-bottom: ${(props) => props.mb ?? ''};
`;
