import React, { useEffect } from 'react';
import {
  Carteira,
  Container,
  TrocarCarteiraContainer,
  TrocarCarteiraText,
  StyledExchange,
} from './style';
import { LineSmall } from '../Line';
import { useAppDispatch, useAppSelector } from '../../services/reduxHooks';
import { Link, useLocation } from 'react-router-dom';
import { fetchCarteiraList } from '../../services/api/preferenceAPI';
import { cancelarAPI } from '../../services/api/abortAPI';
import {setIsLoadingMinimized} from '../../services/reducers/loadMinimizedSlice';

export default function TrocarCarteira() {
  const [carteiraSelecionada, carteiraList, loadedCarteiraList, isLoadingMinimized] = useAppSelector((state) => [
    state.preference.params.carteira,
    state.preference.carteiraList,
    state.preference.loadedCarteiraList,
    state.loadMinimized.isLoadingMinimized,
  ]);
  const { search, pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loadedCarteiraList) dispatch(fetchCarteiraList());
  }, [loadedCarteiraList]);

  const aliasCart = carteiraList.find(
    (x) => x.nome_portfolio === carteiraSelecionada,
  )?.alias_nome_portfolio;

  const displayCarteira = aliasCart ?? carteiraSelecionada

  /**
   * Caso o modal de loading esteja minimizado, cancelar as chamadas da API
   * ao navegar para a p�gina de selecionar carteira
   * @param e event
   */
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isLoadingMinimized) {
      cancelarAPI();
      setIsLoadingMinimized(false);
    }
  };

  return (
    <Container data-html2canvas-ignore="true">
      <Carteira>Carteira {loadedCarteiraList ? displayCarteira : ''}</Carteira>
      <TrocarCarteiraContainer>
        <Link
          key="1"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            alignItems: 'center',
            display: 'flex',
          }}
          to={{ pathname: '/', search }}
          state={pathname}
          onClick={handleClick}>
          <StyledExchange />
          <TrocarCarteiraText>Trocar Carteira</TrocarCarteiraText>
        </Link>

        <LineSmall />
      </TrocarCarteiraContainer>
    </Container>
  );
}
